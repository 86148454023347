<template>
	<div class="role-content">
		<div class="top-box">
			<button class="add-btn" @click="handleEditRole('add')" v-if="perms.includes('sys:role:add')">新增</button>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="role-name-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<label class="label">角色类型</label>
				<el-select class="search-select" size="small" v-model="roleType" filterable>
					<el-option v-for="(item,i) in roleTypeList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			 <el-table
				:data="roleList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="50">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="角色名称"
					width="180">
				</el-table-column>
				<el-table-column
					prop="remark"
					show-overflow-tooltip
					label="角色说明">
				</el-table-column>
				<el-table-column
					prop="type"
					show-overflow-tooltip
					label="角色类型">
					<template slot-scope="scope">
						<span>{{roleTypeName[scope.row.type]}}</span>
					</template>
				</el-table-column>
				<el-table-column
					label="操作"
					width="120">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditRole('check',scope.row)">查看</button>
						<button class="btn-blue" @click="handleEditRole('edit',scope.row)" v-if="perms.includes('sys:role:update')">编辑</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('sys:role:delete')">删除</button>
						<button class="btn-blue" @click="handleConfigRole(scope.row.id)" v-if="perms.includes('sys:role:assign')">分配权限</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-role ref="editRole" @getRoleList="getRoleList"></edit-role>
		<config-role ref="configRole"></config-role>
	</div>
</template>
<script>
import EditRole from './components/edit-role'
import ConfigRole from './components/config-role'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditRole,
		ConfigRole,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			roleType: '',
			roleTypeList: [
				{
					id: '',
					name: '全部'
				},
				{
					id: 1,
					name: '超级管理员'
				},
				{
					id: 2,
					name: '管理员'
				},
				{
					id: 3,
					name: '普通成员'
				},
				{
					id: 4,
					name: '学员'
				},
				{
					id: 5,
					name: '经销商'
				},
				{
					id: 6,
					name: '教师'
				}
			],
			roleTypeName: {
				1: '超级管理员',
				2: '管理员',
				3: '普通成员',
				4: '学员',
				5: '经销商',
				6: '教师'
			},
			pageNo: 1,
			pageSize: 20,
			total: 0,
			roleList: []
		}
	},
	created() {
		this.getRoleList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 新增/编辑/查看角色
		handleEditRole(type,data) {
			this.$refs.editRole.init(type,data);
		},
		// 检索
		handleSearch() {
			this.pageNo = 1;
			this.getRoleList();
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前角色?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'sysRoleDelete',
					true,
					{
						id: data.id
					},
					function (r) {
						if (r.code == "0") {
							that.getRoleList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.roleType = '';
			this.getRoleList();
		},
		// 获取角色数据
		getRoleList() {
			let that = this;
			that.$request.get(
				"sysRoleList",
				true,
				{
					searchKey: that.searchKey,
					type: that.roleType,
					pageNo: that.pageNo,
					pageSize: that.pageSize
				},
				function (r) {
					if (r.code == "0") {
						that.roleList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleSizeChange() {
			this.getRoleList();
		},
		handleCurrentChange() {
			this.getRoleList();
		},
		// 分配权限
		handleConfigRole(id) {
			this.$refs.configRole.init(id);
		}
	}
}
</script>
<style lang="scss" scoped>
.role-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.role-name-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		margin-right: 10px;
		background-color: #fff;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
</style>
