<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="350px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
            <el-form-item label="角色名称" prop="name">
                <el-input v-model="dataForm.name" placeholder="角色名称" maxlength="32" :disabled="readonly"></el-input>
            </el-form-item>
			<el-form-item label="角色类型" prop="type">
                <el-select v-model="dataForm.type" filterable :disabled="readonly">
					<el-option v-for="(item,i) in roleTypeList" :key="i" :value="item.id" :label="item.name"></el-option>
				</el-select>
            </el-form-item>
			<el-form-item label="角色描述" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="角色描述" maxlength="128" :disabled="readonly"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button v-if="!readonly" class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增角色',
			visible: false,
			readonly: false,
			roleTypeList: [
				{
					id: 1,
					name: '超级管理员'
				},
				{
					id: 2,
					name: '管理员'
				},
				{
					id: 3,
					name: '普通成员'
				},
				{
					id: 4,
					name: '学员'
				},
				{
					id: 5,
					name: '经销商'
				},
				{
					id: 6,
					name: '教师'
				}
			],
			dataForm: {
				name: '',
				type: '',
				remark: ''
			},
			submitUrl: 'sysRoleAdd',
			dataRule: {
				name: [
					{required: true,message: '角色名称不能为空', trigger: "blur"}
				],
				type: [
					{required: true,message: '请选择角色类型', trigger: "change"}
				],
				// remark: [
				// 	{required: true,message: '角色描述不能为空', trigger: "blur"}
				// ]
			}
		}
	},
	methods: {
		init(type,data) {
			this.visible = true;
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(type == 'add') {
					this.title = '新增角色';
					this.submitUrl = 'sysRoleAdd';
					this.readonly = false;
					delete this.dataForm.id;
				}
				else if(type == 'edit') {
					this.title = '修改角色';
					this.dataForm.type = data.type;
					this.dataForm.name = data.name;
					this.dataForm.remark = data.remark;
					this.dataForm.id = data.id;
					this.submitUrl = 'sysRoleUpdate';
					this.readonly = false;
				}
				else {
					this.title = '查看角色';
					this.dataForm.type = data.type;
					this.dataForm.name = data.name;
					this.dataForm.remark = data.remark;
					this.submitUrl = 'sysRoleUpdate';
					this.readonly = true;
				}
			})
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						true,
						that.dataForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getRoleList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 10px;
	.el-select{
		width: 100%;
	}
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
