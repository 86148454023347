<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="350px">
		<span slot="title" class="dialog-title">
			<span>权限分配</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<div class="role-tree-box">
			<el-tree
				:data="treeData"
				:check-strictly="true"
				show-checkbox
				node-key="id"
                ref="tree"
				:props="defaultProps"
				@check="handleCheck"
				class="tree-box">
				<span class="custom-tree-node" slot-scope="{ node, data }">
					<span>
						{{ node.label }}
					</span>
				</span>
			</el-tree>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			visible: false,
			id: '',
			permList: [],
			defaultProps: {
				children: "children",
				label: "label"
			},
			treeData: []
		}
	},
	methods: {
		init(id) {
			this.visible = true;
			this.id = id;
			this.getRoleTree();
			this.$nextTick(() => {
				this.getRoleList();

			})
		},
		// 获取权限数据
		getRoleTree() {
			let that = this;
			that.$request.get(
				"sysRoleTree",
				true,
				{},
				function (r) {
					if (r.code == "0") {
						that.treeData = r.data;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 获取当前角色权限
		getRoleList() {
			let that = this;
			that.$request.get(
				'sysGetRoleList',
				true,
				{
					roleId: that.id,
				},
				function (r) {
					if (r.code == "0") {
						let arr = r.data.checked.includes('1') ? r.data.checked : r.data.checked.concat(['1']);
						that.$refs.tree.setCheckedKeys(arr);
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		handleCheck(data,nodes) {
			// 当前菜单mbcode
            let cmbcode = data.id;
            // 所有选中节点的id
            let allKeys = nodes.checkedKeys;
            let allNodes = nodes.checkedNodes;
            // 当前菜单是否被选中，选中true,取消false
			let checkedKey = allKeys.includes(cmbcode);
			// 父菜单
            if(data.children) {
                let childrenNodes = data.children;
                // 父菜单被选中子菜单全选
                if(checkedKey) {
                    let addArr = [];
                    let addChildArr = [];
                    childrenNodes.forEach(item => {
                        // 二级
                        addChildArr.push(item.id)
                        if(item.children){
                            item.children.forEach(child => {
                                // 三级
                                addChildArr.push(child.id)
                                if(child.children) {
                                    child.children.forEach(son => {
                                        // 四级
                                        addChildArr.push(son.id)
                                    })
                                }
                            })
                        }
                    });
                    addArr = [...addChildArr,...allKeys];
                    this.$refs.tree.setCheckedKeys(Array.from(new Set(addArr)));
				}
				// 父菜单勾除子菜单同时勾除
                else{
                    let childrenNodes = data.children;
                    let deleteChildArr = [];
                    childrenNodes.forEach(item => {
                        // 二级
                        deleteChildArr.push(item.id)
                        if(item.children){
                            item.children.forEach(child => {
                                // 三级
                                deleteChildArr.push(child.id)
                                if(child.children) {
                                    child.children.forEach(son => {
                                        // 四级
                                        deleteChildArr.push(son.id)
                                    })
                                }
                            })
                        }
                    });
                    let deleteKeys = [];
                    allKeys.forEach(item => {
                        if(!deleteChildArr.includes(item)) {
                            deleteKeys.push(item);
                        }
                    })
                    this.$refs.tree.setCheckedKeys(Array.from(new Set(deleteKeys)));
                }
			}
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			let that = this;
			that.$request.post(
				'sysRoleConfig',
				true,
				{
					roleId: that.id,
					permList: that.$refs.tree.getCheckedKeys()
				},
				function (r) {
					if (r.code == "0") {
						that.visible = false;
						that.$message.success('保存成功');
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.role-tree-box{
	min-height: 300px;
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
